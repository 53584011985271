import React, { useState } from 'react';
import { BaseProps } from '../@types/common';
import { twMerge } from 'tailwind-merge';

type Props = BaseProps & {
  title: string;
  children: React.ReactNode;
};

const Collapse: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={twMerge("py-3", props.className)}>
      <button
        onClick={toggle}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        {props.title}
      </button>
      {isOpen && (
        <div className="border border-gray-300 rounded overflow-y-auto max-h-full">
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Collapse;
