// 2024-11-08 k_nagayama submit追加
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonFileChoose from '../components/ButtonFileChoose';
import ListItemFile from '../components/ListItemFile';
import useSubmitFiles from '../hooks/useSubmitFiles';
import DialogFilePreview from '../components/DialogFilePreview';

const SubmitFilesPage: React.FC = () => {
  const { t } = useTranslation();

  const [previewFileUrl, setPreviewFileUrl] = useState<string>();
  const [previewFileName, setPreviewFileName] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    myFiles,
    postSubmitFile,
    getPresignedDownloadUrl,
  } = useSubmitFiles();

  const onClickNewFileButton = (fileList: FileList) => {
    for (const file of fileList) {
      postSubmitFile(file);
    }
  }

  const onClickFile = useCallback(async (fileId: string, fileName: string) => {
    setPreviewFileUrl((await getPresignedDownloadUrl(fileId)).data);
    setPreviewFileName(fileName)
    setModalOpen(true);
  }, [getPresignedDownloadUrl]);

  return (
    <>
      {previewFileUrl && <DialogFilePreview
        fileUrl={previewFileUrl}
        fileName={previewFileName}
        isOpen={isModalOpen}
        onClose={() => { setModalOpen(false); }}
      ></DialogFilePreview >}
      <div className="flex h-full justify-center">
        <div className="w-2/3">
          <div className="size-full pt-8">
            <div className="flex items-end justify-between">
              <div className="flex items-center gap-2">
                <div className="text-xl font-bold">{t('submitFiles.label.myFiles')}</div>
              </div>
              <ButtonFileChoose
                onChange={onClickNewFileButton}
                accept={"*/*"}>
                {t('submitFiles.button.submitFile')}
              </ButtonFileChoose>
            </div>
            <div className="mt-2 border-b border-gray"></div>

            <div className="h-4/5 overflow-x-hidden overflow-y-scroll border-b border-gray pr-1 scrollbar-thin scrollbar-thumb-aws-font-color/20 ">
              {myFiles?.length === 0 && (
                <div className="flex size-full items-center justify-center italic text-dark-gray">
                  {t('submitFiles.label.noFiles')}
                </div>
              )}
              {myFiles?.map((file) => (
                <ListItemFile
                  key={file.id}
                  // Add "Unsupported" prefix for bots created under opposite VITE_APP_ENABLE_KB environment state
                  file={file}
                  onClick={() => { onClickFile(file.id, file.title) }}
                  className="last:border-b-0">
                </ListItemFile>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitFilesPage;
// 2024-11-08 k_nagayama submit追加 ここまで
