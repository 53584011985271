import { twMerge } from 'tailwind-merge';
import { BaseProps } from '../@types/common';
import { RiRobot2Fill, RiRobot2Line } from 'react-icons/ri';
import ButtonIcon from './ButtonIcon';
import { PiChatText, PiChatTextFill } from 'react-icons/pi';

type Props = {
  screenBotMode: boolean;
  setScreenBotMode: (mode: boolean) => void;
} & BaseProps;

const SwitchScreenBotMode: React.FC<Props> = (props) => {

  return (
    <div
      className={twMerge(
        props.className,
        'flex justify-center gap-2 rounded-lg border border-light-gray bg-light-gray p-1 text-sm'
      )}>
      <ButtonIcon
        className={twMerge(
          'relative flex w-40 items-center rounded-lg p-2 font-bold',
          props.screenBotMode
            ? 'border-light-gray bg-white text-dark-gray'
            : 'bg-aws-sea-blue text-aws-font-color-white'
        )}
        onClick={() => { props.setScreenBotMode(false) }}>
        {props.screenBotMode ? <PiChatText /> : <PiChatTextFill />}
      </ButtonIcon>
      <ButtonIcon
        className={twMerge(
          'relative flex w-40 items-center rounded-lg p-2 font-bold',
          !props.screenBotMode
            ? 'border-light-gray bg-white text-dark-gray'
            : 'bg-aws-sea-blue text-aws-font-color-white'
        )}
        onClick={() => { props.setScreenBotMode(true) }}>
        {props.screenBotMode ? <RiRobot2Fill /> : <RiRobot2Line />}
      </ButtonIcon>
    </div>
  );
};

export default SwitchScreenBotMode;
