import React, { useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import useEzAuthApi from '../hooks/useEzAuthApi';
import { PiCircleNotch } from 'react-icons/pi';
import { t } from 'i18next';

const EzAuthPage: React.FC = () => {

  const { userId, ezAuthId } = useParams();
  const navigate = useNavigate();
  const { getUserName } = useEzAuthApi();

  // Auth.configure({
  //   authenticationFlowType: 'CUSTOM_AUTH'
  // });

  useEffect(() => {
    (async () => {
      if (!userId || !ezAuthId) { return; }
      const response = await getUserName({ userId, ezAuthId });
      await signIn({ username: response.data.userName });
      await confirmSignIn({
        challengeResponse: ezAuthId
      });
      navigate("/");
    })();
  })

  return (
    <h3>
      <PiCircleNotch className="-ml-1 mr-2 animate-spin size-20"></PiCircleNotch>
      {t("ezAuth.authenticating")}
    </h3>
  );
};

export default EzAuthPage;
