// 2024-11-13 k_nagayama スピーチボタンを改良
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseProps } from '../@types/common';
import ButtonIcon from './ButtonIcon';
import useSpeech from '../hooks/useSpeech';
import { AiOutlineSound, AiTwotonePauseCircle } from 'react-icons/ai';
import { PiSpinnerGap } from 'react-icons/pi';

type Props = BaseProps & {
  content?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const ButtonSpeech: React.FC<Props> = (props) => {

  const { speechStatus, onPlaySpeech, onStopSpeech } = useSpeech();
  const [isClicked, setIsClicked] = useState(false);

  const isSpeeching = useMemo(() => {
    return isClicked && speechStatus == 'speeching'
  }, [isClicked, speechStatus])

  const otherSpeeching = useMemo(() => {
    return !isClicked && speechStatus == 'speeching'
  }, [isClicked, speechStatus])

  const isLoading = useMemo(() => {
    return isClicked && speechStatus == 'loading'
  }, [isClicked, speechStatus])

  const otherLoading = useMemo(() => {
    return !isClicked && speechStatus == 'loading'
  }, [isClicked, speechStatus])

  const onClick = useCallback(() => {
    switch (speechStatus) {
      case 'speeching':
        if (isSpeeching) {
          onStopSpeech();
        }
        break;
      case 'stop':
        if (props.content) {
          onPlaySpeech(props.content)
        }
        setIsClicked(true);
        break;
      case 'loading':
      default:
        break;
    }
  }, [isSpeeching, onPlaySpeech, onStopSpeech, props.content, speechStatus])

  useEffect(() => {
    if (speechStatus == "stop" && isClicked) {
      setIsClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speechStatus])

  return (
    <ButtonIcon
      className="text-dark-gray"
      disabled={props.disabled || otherSpeeching || otherLoading}
      onClick={onClick}
    >
      {isSpeeching ? <AiTwotonePauseCircle /> : isLoading ? <PiSpinnerGap className="animate-spin" /> : <AiOutlineSound />}
      {props.children}
    </ButtonIcon>
  );
};

export default ButtonSpeech;
// 2024-11-13 k_nagayama スピーチボタンを改良 ここまで
