// 2024-11-08 k_nagayama submit追加
import { GetSubmitedFilesRequest, GetSubmitedFilesResponse, GetSubmitPresignedDownloadUrlResponse, GetSubmitPresignedUploadUrlResponse } from '../@types/submit';
import useBotApi from './useBotApi';
import useHttp from './useHttp';

const useSubmitApi = () => {
  const http = useHttp();
  const { uploadFile } = useBotApi();

  return {
    getPresignedUploadUrl: (filename: string) => {
      return http.getOnce<GetSubmitPresignedUploadUrlResponse>(`submit/presigned-upload-url/`, { filename });
    },
    getPresignedDownloadUrl: (key: string) => {
      return http.getOnce<GetSubmitPresignedDownloadUrlResponse>(`submit/presigned-download-url/`, { key });
    },
    uploadFile,  // useBotApiの物を流用
    files: (
      req: GetSubmitedFilesRequest,
      refreshIntervalFunction?: (data?: GetSubmitedFilesResponse) => number
    ) => {
      return http.get<GetSubmitedFilesResponse>(['submit/files', req], {
        refreshInterval: refreshIntervalFunction,
      });
    },
  };
};

export default useSubmitApi;
// 2024-11-08 k_nagayama submit追加 ここまで
