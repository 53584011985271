import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChatMessageMarkdown from './ChatMessageMarkdown';
import ButtonCopy from './ButtonCopy';
import {
  PiCaretLeftBold,
  PiNotePencil,
  PiUserFill,
  PiThumbsDown,
  PiThumbsDownFill,
} from 'react-icons/pi';
import { BaseProps } from '../@types/common';
import {
  DisplayMessageContent,
  RelatedDocument,
  PutFeedbackRequest,
} from '../@types/conversation';
import ButtonIcon from './ButtonIcon';
import Textarea from './Textarea';
import Button from './Button';
import ModalDialog from './ModalDialog';
import { useTranslation } from 'react-i18next';
import DialogFeedback from './DialogFeedback';
import UploadedAttachedFile from './UploadedAttachedFile';
import { TEXT_FILE_EXTENSIONS } from '../constants/supportedAttachedFiles';
import AgentToolList from '../features/agent/components/AgentToolList';
import { AgentToolsProps } from '../features/agent/xstates/agentThink';
import ButtonSpeech from './ButtonSpeech';

type Props = BaseProps & {
  isAgentThinking: boolean;
  tools?: AgentToolsProps;
  chatContent?: DisplayMessageContent;
  relatedDocuments?: RelatedDocument[];
  onChangeMessageId?: (messageId: string) => void;
  onSubmit?: (messageId: string, content: string) => void;
  onSubmitFeedback?: (messageId: string, feedback: PutFeedbackRequest) => void;
};

const ChatMessage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [changedContent, setChangedContent] = useState('');
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const { relatedDocuments } = props;

  const [firstTextContent, setFirstTextContent] = useState(0);

  useEffect(() => {
    if (props.chatContent) {
      setFirstTextContent(
        props.chatContent.content.findIndex(
          (content) => content.contentType === 'text'
        )
      );
    }
  }, [props.chatContent]);

  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [isOpenPreviewImage, setIsOpenPreviewImage] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [dialogFileName, setDialogFileName] = useState<string>('');
  const [dialogFileContent, setDialogFileContent] = useState<string | null>(
    null
  );

  const chatContent = useMemo<DisplayMessageContent | undefined>(() => {
    return props.chatContent;
  }, [props]);

  const nodeIndex = useMemo(() => {
    return chatContent?.sibling.findIndex((s) => s === chatContent.id) ?? -1;
  }, [chatContent]);

  const onClickChange = useCallback(
    (idx: number) => {
      props.onChangeMessageId
        ? props.onChangeMessageId(chatContent?.sibling[idx] ?? '')
        : null;
    },
    [chatContent?.sibling, props]
  );

  const onSubmit = useCallback(() => {
    props.onSubmit
      ? props.onSubmit(chatContent?.sibling[0] ?? '', changedContent)
      : null;
    setIsEdit(false);
  }, [changedContent, chatContent?.sibling, props]);

  const handleFeedbackSubmit = useCallback(
    (messageId: string, feedback: PutFeedbackRequest) => {
      if (chatContent) {
        props.onSubmitFeedback?.(messageId, feedback);
      }
      setIsFeedbackOpen(false);
    },
    [chatContent, props]
  );

  const isDisplay = useMemo(() => {
    return chatContent?.content.every((content) => {
      if (content.contentType == 'image') { return true; }
      const displayBody = content.body.replace(/<hiddenContent>[\s\S]*?<\/hiddenContent>/g, '');
      if (displayBody.includes(t('app.chatWaitingSymbol'))) { return true; }
      return /[^\s]/.test(displayBody);// 空白文字しかない場合はfalseを返す
    })
  }, [chatContent?.content, t]);

  if (!isDisplay) { return <></> }

  return (
    <>
      <div className={`${props.className ?? ''} grid grid-cols-12 gap-2 p-3 `}>
        <div className="col-start-1 lg:col-start-2 ">
          {(chatContent?.sibling.length ?? 0) > 1 && (
            <div className="flex items-center justify-start text-sm lg:justify-end">
              <ButtonIcon
                className="text-xs"
                disabled={nodeIndex === 0}
                onClick={() => {
                  onClickChange(nodeIndex - 1);
                }}>
                <PiCaretLeftBold />
              </ButtonIcon>
              {nodeIndex + 1}
              <div className="mx-1">/</div>
              {chatContent?.sibling.length}
              <ButtonIcon
                className="text-xs"
                disabled={nodeIndex >= (chatContent?.sibling.length ?? 0) - 1}
                onClick={() => {
                  onClickChange(nodeIndex + 1);
                }}>
                <PiCaretLeftBold className="rotate-180" />
              </ButtonIcon>
            </div>
          )}
        </div>

        <div className="order-first col-span-12 flex lg:order-none lg:col-span-8 lg:col-start-3">
          {chatContent?.role === 'user' && (
            <div className="h-min rounded bg-aws-sea-blue p-2 text-xl text-white">
              <PiUserFill />
            </div>
          )}
          {chatContent?.role === 'assistant' && (
            <div className="min-w-[2.3rem] max-w-[2.3rem]">
              <img src="/images/bedrock_icon_64.png" className="rounded" />
            </div>
          )}

          <div className="ml-5 grow ">
          {chatContent?.role === 'assistant' && (
            <div className="flex flex-col">
              {props.isAgentThinking ? (
                <AgentToolList tools={props.tools ?? {}} isRunning={true} />
              ) : (
                <>
                  {chatContent.thinkingLog && (
                    <div className="mb-3 mt-0">
                      <AgentToolList
                        tools={props.tools ?? {}}
                        isRunning={false}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
            {chatContent?.role === 'user' && !isEdit && (
              <div>
                {chatContent.content.some(
                  (content) => content.contentType === 'image'
                ) && (
                    <div key="images">
                      {chatContent.content.map((content, idx) => {
                        if (content.contentType === 'image') {
                          const imageUrl = `data:${content.mediaType};base64,${content.body}`;
                          return (
                            <img
                              key={idx}
                              src={imageUrl}
                              className="mb-2 h-48 cursor-pointer"
                              onClick={() => {
                                setPreviewImageUrl(imageUrl);
                                setIsOpenPreviewImage(true);
                              }}
                            />
                          );
                        }
                      })}
                    </div>
                  )}
                {chatContent.content.some(
                  (content) => content.contentType === 'attachment'
                ) && (
                    <div key="files" className="my-2 flex">
                      {chatContent.content.map((content, idx) => {
                        if (content.contentType === 'attachment') {
                          const isTextFile = TEXT_FILE_EXTENSIONS.some((ext) =>
                            content.fileName?.toLowerCase().endsWith(ext)
                          );
                          return (
                            <UploadedAttachedFile
                              key={idx}
                              fileName={content.fileName ?? ''}
                              onClick={
                                // Only text file can be previewed
                                isTextFile
                                  ? () => {
                                    const textContent = new TextDecoder(
                                      'utf-8'
                                    ).decode(
                                      Uint8Array.from(atob(content.body), (c) =>
                                        c.charCodeAt(0)
                                      )
                                    ); // base64 encoded text to be decoded string
                                    setDialogFileName(content.fileName ?? '');
                                    setDialogFileContent(textContent);
                                    setIsFileModalOpen(true);
                                  }
                                  : undefined
                              }
                            />
                          );
                        }
                      })}
                    </div>
                  )}
                {chatContent.content.some(
                  (content) => content.contentType === 'text'
                ) &&
                  chatContent.content.map((content, idx) => {
                    if (content.contentType === 'text') {
                      const displayBody = content.body.replace(/<hiddenContent>[\s\S]*?<\/hiddenContent>/g, '');
                      return (
                        <React.Fragment key={idx}>
                          {displayBody.split('\n').map((c, idxBody) => (
                            <div key={idxBody}>{c}</div>
                          ))}
                        </React.Fragment>
                      );
                    }
                  })}
                <ModalDialog
                  isOpen={isOpenPreviewImage}
                  onClose={() => setIsOpenPreviewImage(false)}
                  // Set image null after transition end
                  widthFromContent={true}
                  onAfterLeave={() => setPreviewImageUrl(null)}>
                  {previewImageUrl && (
                    <img
                      src={previewImageUrl}
                      className="mx-auto max-h-[80vh] max-w-full rounded-md"
                    />
                  )}
                </ModalDialog>
                <ModalDialog
                  isOpen={isFileModalOpen}
                  onClose={() => setIsFileModalOpen(false)}
                  widthFromContent={true}
                  title={dialogFileName ?? ''}>
                  <div className="relative flex size-auto max-h-[80vh] max-w-[80vh] flex-col">
                    <div className="overflow-auto px-4">
                      <pre className="whitespace-pre-wrap break-all">
                        {dialogFileContent}
                      </pre>
                    </div>
                  </div>
                </ModalDialog>
              </div>
            )}
            {isEdit && (
              <div>
                <Textarea
                  className="bg-transparent"
                  value={changedContent}
                  noBorder
                  onChange={(v) => setChangedContent(v)}
                />
                <div className="flex justify-center gap-3">
                  <Button onClick={onSubmit}>{t('button.SaveAndSubmit')}</Button>
                  <Button
                    outlined
                    onClick={() => {
                      setIsEdit(false);
                    }}>
                    {t('button.cancel')}
                  </Button>
                </div>
              </div>
            )}
            {chatContent?.role === 'assistant' && (
              chatContent.content.map((content, idx) => {
                if (content.contentType === 'image') {
                  const imageUrl = `data:${content.mediaType};base64,${content.body}`;
                  return (
                    <img
                      key={idx}
                      src={imageUrl}
                      className="mb-2 h-48 cursor-pointer"
                      onClick={() => {
                        setPreviewImageUrl(imageUrl);
                        setIsOpenPreviewImage(true);
                      }}
                    />
                  );
                } else {
                  const displayBody = content.body.replace(/<hiddenContent>[\s\S]*?<\/hiddenContent>/g, '');
                  if (chatContent.model == 'titan-image-gen-1' && displayBody == t('app.chatWaitingSymbol')) {
                    return (<>
                      <div
                        key={idx}
                        className="bg-gray rounded size-48 flex items-center justify-center">
                        <span className="text-gray-500">Loading...</span>
                      </div>
                    </>)
                  }
                  return (
                    <ChatMessageMarkdown
                      key={idx}
                      relatedDocuments={relatedDocuments}
                      messageId={chatContent.id}>
                      {displayBody}
                    </ChatMessageMarkdown>
                  );
                }
              })
            )}
          </div>
        </div>

        <div className="col-start-11">
          <div className="flex flex-col items-end">
            <div className="flex">
              {chatContent?.role === 'user' && !isEdit && (
                <ButtonIcon
                  className="text-dark-gray"
                  onClick={() => {
                    setChangedContent(chatContent.content[firstTextContent].body.replace(/<hiddenContent>[\s\S]*?<\/hiddenContent>/g, ''));
                    setIsEdit(true);
                  }}>
                  <PiNotePencil />
                </ButtonIcon>
              )}
              {chatContent?.role === 'assistant' && (
                <>
                  <ButtonIcon
                    className="text-dark-gray"
                    onClick={() => setIsFeedbackOpen(true)}>
                    {chatContent.feedback && !chatContent.feedback.thumbsUp ? (
                      <PiThumbsDownFill />
                    ) : (
                      <PiThumbsDown />
                    )}
                  </ButtonIcon>
                  <ButtonCopy
                    className="text-dark-gray"
                    text={chatContent.content[0].body}
                  />
                </>
              )}
              {/* 2024-11-13 k_nagayama スピーチボタンを改良 */}
              {chatContent?.model != 'titan-image-gen-1' && (
                <ButtonSpeech
                  content={chatContent?.content[0].body}
                />
              )}
              {/* 2024-11-13 k_nagayama スピーチボタンを改良 ここまで */}
            </div>
          </div>
        </div>
        <DialogFeedback
          isOpen={isFeedbackOpen}
          thumbsUp={false}
          feedback={chatContent?.feedback ?? undefined}
          onClose={() => setIsFeedbackOpen(false)}
          onSubmit={(feedback) => {
            if (chatContent) {
              handleFeedbackSubmit(chatContent.id, feedback);
            }
          }}
        />
      </div>
      <ModalDialog
        isOpen={isOpenPreviewImage}
        onClose={() => setIsOpenPreviewImage(false)}
        // Set image null after transition end
        widthFromContent={true}
        onAfterLeave={() => setPreviewImageUrl(null)}>
        {previewImageUrl && (
          <img
            src={previewImageUrl}
            className="mx-auto max-h-[80vh] max-w-full rounded-md"
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ChatMessage;