// 2024-11-08 k_nagayama submit追加
import { useCallback } from 'react';
import useSubmitFilesApi from './useSubmitFilesApi';

const useSubmitFiles = () => {
  const { files, getPresignedUploadUrl, uploadFile, getPresignedDownloadUrl } = useSubmitFilesApi();

  const { data: myFiles, mutate: mutateMyFiles } = files({});


  const postSubmitFile = useCallback(async (file: File) => {
    const presignedUrl = (await getPresignedUploadUrl(file.name)).data;
    await uploadFile(presignedUrl, file);
  }, [getPresignedUploadUrl, uploadFile])

  return {
    myFiles,
    mutateMyFiles,
    postSubmitFile,
    getPresignedDownloadUrl
  };
};

export default useSubmitFiles;
// 2024-11-08 k_nagayama submit追加 ここまで
