import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';

import './i18n';
import { Amplify } from 'aws-amplify';
import { router } from './routes';


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
          scopes: ['openid', 'email'],
          redirectSignIn: [import.meta.env.VITE_APP_REDIRECT_SIGNIN_URL],
          redirectSignOut: [import.meta.env.VITE_APP_REDIRECT_SIGNOUT_URL],
          responseType: 'code',
        },
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
